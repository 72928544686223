import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import { HTTPMethod, Response } from 'types/http';

import { getDefaultPagination } from 'utils/pagination';

import { fetchBaseQueryWithAuth } from '../api';
import { TAGS } from './keys';
import { PwcOrdersListQuery, PwcOrdersListResponse } from './types';

const REDUCER_PATH = 'pwcOrdersApi';

export const pwcOrdersApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.ACCOUNT_RELATED],
  endpoints: (builder) => ({
    getPwcOrdersList: builder.query<PwcOrdersListResponse, PwcOrdersListQuery>({
      query: (requestBody) => ({
        url: Endpoint.PwcOrders.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      providesTags: [TAGS.ACCOUNT_RELATED],
      transformResponse: (response: Response<PwcOrdersListResponse>) => {
        if (!response?.data) {
          return { orders: [], pagination: getDefaultPagination() };
        }

        return response.data;
      },
    }),
  }),
});

export const { getPwcOrdersList } = pwcOrdersApi.endpoints;
export const { useGetPwcOrdersListQuery } = pwcOrdersApi;
export default pwcOrdersApi;
