import { PageWrapper } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { useGetMerchantQuery } from 'services/merchants';

import IconBuild from 'components/icons/build';
import LoadingIndicator from 'components/loadingIndicator/loadingIndicator';
import { TabItem } from 'components/tabs';

import RoutePath from 'router/path';

import { AccountSettings } from './accountSettings';
import { KEYS, LABELS } from './keys';

export const MerchantViewContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matchId = useMatch(KEYS.ROUTE_MATCH_ID);
  const match = useMatch(KEYS.ROUTE_MATCH);
  const selectedMatch = matchId || match;
  const { route } = selectedMatch?.params || {};
  const { uuid } = selectedMatch?.params || {};

  const { isLoading } = useGetMerchantQuery(uuid!, {
    skip: !uuid,
  });

  const tabs: TabItem[] = [
    {
      key: KEYS.TABS.GENERAL,
      label: t(LABELS.TABS.GENERAL),
      icon: <IconBuild />,
      onClick: () => navigate(RoutePath.AccountSettings.General),
    },
  ];

  const selectedTab: TabItem | undefined = tabs.find((tab) => tab.key === route);

  const breadcrumbsStart = uuid
    ? LABELS.S4C_USER_BREADCRUMBS.map((item) => t(item))
    : LABELS.PARTNER_USER_BREADCRUMBS.map((item) => t(item));

  const breadcrumbs = [...breadcrumbsStart, selectedTab?.label].filter(Boolean) as string[];

  if (isLoading) {
    return (
      <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
        <LoadingIndicator padded />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper pageTitle={t(LABELS.PAGE_TITLE)} breadcrumbs={breadcrumbs}>
      <AccountSettings tabs={tabs} selected={selectedTab} />
    </PageWrapper>
  );
};
