export interface PwcOrder {
  uuid: string;
  merchantUuid: string;
  paymentProvider: PaymentProvider | null;
  paymentProviderId: string;
  paymentProviderConnectionName: string | null;
  paymentProviderStatus: string | null;
  liquidityProvider: LiquidityProvider | null;
  onChainTransactionHash: string;
  fromWallet: string;
  network: string;
  summary: string;
  status: OrderStatus;
  createdAt: string;
  expiredAt: string;
  customerUuid: string;
  customerEmail: string;
  cryptoAmount: string | null;
  cryptoCurrencyCode: string | null;
  fiatAmount: string | null;
  fiatCurrencyCode: string | null;
  toWalletAddress: string | null;
  toWalletMemo: string | null;
  assetUuid: string;
  depositUuid: string;
  redirectUrl: string;
  cancelRedirectUrl: string;
  cryptoPrice: string | null;
  settlementStatus: string | null;
  settlementPaymentRail: string | null;
  settlementCurrency: string | null;
  settlementDestination: string;
  settlementOnchainTransactionHash: string;
  processingFeePercent: string | null;
  processingFeeAmount: string | null;
  paymentProviderConnectionType: string | null;
  paymentProviderStatusUpdatedAt: string | null;
  externalCustomerId: string | null;
}

export enum PaymentProvider {
  Manual = 'manual',
  Mesh = 'mesh',
  WalletConnect = 'walletConnect',
}

export enum LiquidityProvider {
  Bridge = 'bridge',
}

export enum OrderStatus {
  Initiated = 'INITIATED',
  AwaitingDeposit = 'AWAITING_DEPOSIT',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Canceled = 'CANCELED',
}
