import RoutePath from 'router/path';

export const KEYS = {
  ROUTE_MATCH: `${RoutePath.Pwc.Accounts.ById}/:route`,
  ROUTE_MATCH_ID: `${RoutePath.Pwc.Accounts.ById}/:route`,
  TABS: {
    GENERAL: 'general',
  },
};

export const LABELS = {
  S4C_USER_BREADCRUMBS: [
    ['page.merchant.s4cUserBreadcrumbsMerchantAccounts', 'Merchant Accounts'],
    ['page.merchant.s4cUserBreadcrumbsAccountSettings', 'Account Settings'],
  ],
  PARTNER_USER_BREADCRUMBS: [['page.merchant.partnerUserBreadcrumbs', 'Account Settings']],
  PAGE_TITLE: ['page.merchant.title', 'Account Settings'],
  TABS: {
    GENERAL: ['page.merchant.tabs.general', 'General'],
  },
};
