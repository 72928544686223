import { createApi } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';
import { Endpoint } from 'services/endpoints';

import { HTTPMethod, Response, SuccessResponse } from 'types/http';

import { getDefaultPagination } from '../../utils/pagination';
import { fetchBaseQueryWithAuth } from '../api';
import { TAGS } from './keys';
import { MerchantInfo, MerchantsInfoResponse, MerchantsListQuery, MerchantsListResponse } from './types';

const REDUCER_PATH = 'merchantsApi';

export const merchantsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.MERCHANT_LIST],
  endpoints: (builder) => ({
    getMerchantsList: builder.query<MerchantsListResponse, MerchantsListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Merchants.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<MerchantsListResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination(), requestId: response.requestId };
        }

        return response.data;
      },
      providesTags: [TAGS.MERCHANT_LIST],
    }),
    getMerchant: builder.query<MerchantsInfoResponse, string>({
      query: (uuid) => Endpoint.Merchants.ById(uuid),
      forceRefetch: () => true,
    }),
    updateMerchant: builder.mutation<Response<SuccessResponse>, Partial<MerchantInfo>>({
      query: (requestBody) => ({
        url: Endpoint.Merchants.ById(requestBody.uuid!),
        method: HTTPMethod.PUT,
        body: omit(requestBody, 'uuid'),
      }),
    }),
  }),
});

export const { getMerchantsList } = merchantsApi.endpoints;
export const { useGetMerchantsListQuery, useLazyGetMerchantsListQuery, useGetMerchantQuery, useUpdateMerchantMutation } = merchantsApi;
export default merchantsApi;
