import { t } from 'i18next';
import { MerchantInfo } from 'services/merchants/types';

import RoutePath from 'router/path';

import { SettingsFormField } from '../../types';

export const FORM_FIELDS: Record<string, keyof MerchantInfo> = {
  ACCOUNT_ID: 'uuid',
  ACCOUNT_NAME: 'name',
  MERCHANT_ID: 'shift4MerchantId',
  PROCESSING_FEE: 'processingFee',
  WEBHOOK_URL: 'webhookUrl',
  STATUS: 'status',
};

export const KEYS = {
  ROUTE_MATCH: `${RoutePath.Pwc.Accounts.ById}/:route`,
  ROUTE_MATCH_ID: `${RoutePath.Pwc.Accounts.ById}/:route`,
};

export const LABELS = {
  PANEL_TITLE: ['page.merchantSettings.general.title', 'General'],
  SAVE_BUTTON_LABEL: ['page.merchantSettings.general.save', 'Save'],
  CANCEL_BUTTON_LABEL: ['page.merchantSettings.general.cancel', 'Cancel'],
  FORM_FIELDS: {
    ACCOUNT_ID: ['page.merchantSettings.general.form.accountId', 'Account ID'],
    ACCOUNT_NAME: ['page.merchantSettings.general.form.accountName', 'Account Name'],
    MERCHANT_ID: ['page.merchantSettings.general.form.merchantId', 'Shift4 Merchant ID'],
    WEBHOOK_URL: ['page.merchantSettings.general.form.webhookURL', 'Webhook URL'],
    PROCESSING_FEE: ['page.merchantSettings.general.form.processingFee', 'Processing Fee %'],
    STATUS: ['page.merchantSettings.general.form.status', 'Status'],
    CHANGE_STATUS: ['page.merchantSettings.general.form.changeStatus', 'Change Status'],
    PLACEHOLDERS: {
      ACCOUNT_ID: ['page.merchantSettings.general.form.accountId.placeholder', 'Account ID'],
      ACCOUNT_NAME: ['page.merchantSettings.general.form.accountName.placeholder', 'Account Name'],
      MERCHANT_ID: ['page.merchantSettings.general.form.merchantId.placeholder', 'Enter Shift4 Merchant ID'],
      WEBHOOK_URL: ['page.merchantSettings.general.form.webhookURL.placeholder', 'https://'],
      PROCESSING_FEE: ['page.merchantSettings.general.form.processingFee.placeholder', 'Processing Fee %'],
    },
    VALUES: {
      PROCESSING_FEE: ['page.merchantSettings.general.form.value.processingFee', '{{fee}}%'],
      MERCHANT_STATUS_ACTIVE: ['page.merchantSettings.general.form.value.merchantStatusActive', 'Active when Settlement account is active'],
      MERCHANT_STATUS_DEACTIVATED: ['page.merchantSettings.general.form.value.merchantStatusDeactivated', 'Deactivated'],
      MERCHANT_STATUS_INACTIVE: [
        'page.merchantSettings.general.form.value.merchantStatusInactive',
        'Inactive - Add a Settlement account to activate your account',
      ],
    },
    ACTIONS: {
      DEACTIVATE: ['page.merchantSettings.general.form.action.deactivate', 'Deactivate'],
      ACTIVATE: ['page.merchantSettings.general.form.action.activate', 'Activate'],
    },
    MESSAGES: {
      YES: ['page.merchantSettings.general.form.messages.yes', 'Yes'],
      NO: ['page.merchantSettings.general.form.messages.no', 'No'],
    },
  },
  ADORNMENTS: {
    PERCENT: '%',
    HTTPS: 'https://',
  },
  MODAL: {
    TITLE: ['page.merchantSettings.general.modal.title', 'Suspend Account'],
    CONFIRM: ['page.merchantSettings.general.modal.confirm', 'Confirm'],
    CANCEL: ['page.merchantSettings.general.modal.cancel', 'Cancel'],
    HEADER: ['page.merchantSettings.general.modal.header', 'Are you sure?'],
    CONFIRM_CHOICE_IMAGE_ALT: ['page.merchantSettings.general.modal.confirmChoiceImageAlt', 'Confirm choice image'],
    DEACTIVATION_MESSAGE: ['page.merchantSettings.general.modal.deactivationMessage', 'The account <b>{{name}}</b> will be deactivated.'],
  },
};

export const formFields: SettingsFormField[] = [
  {
    key: FORM_FIELDS.ACCOUNT_ID,
    label: t(LABELS.FORM_FIELDS.ACCOUNT_ID),
  },
  {
    key: FORM_FIELDS.ACCOUNT_NAME,
    label: t(LABELS.FORM_FIELDS.ACCOUNT_NAME),
  },
  {
    key: FORM_FIELDS.MERCHANT_ID,
    label: t(LABELS.FORM_FIELDS.MERCHANT_ID),
  },
  {
    key: FORM_FIELDS.WEBHOOK_URL,
    label: t(LABELS.FORM_FIELDS.WEBHOOK_URL),
  },
  {
    key: FORM_FIELDS.PROCESSING_FEE,
    label: t(LABELS.FORM_FIELDS.PROCESSING_FEE),
  },
  {
    key: FORM_FIELDS.STATUS,
    label: t(LABELS.FORM_FIELDS.STATUS),
  },
  {
    key: FORM_FIELDS.CHANGE_STATUS,
    label: t(LABELS.FORM_FIELDS.CHANGE_STATUS),
  },
];
