import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { omitBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useNotifications } from 'providers/notifications/useNotifications';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { useGetMerchantQuery, useUpdateMerchantMutation } from 'services/merchants';
import { MerchantInfo, MerchantStatus } from 'services/merchants/types';

import { hasUserS4cRole } from 'pages/orders/view/utils';

import { selectUserLevel } from 'state/slices/userSlice';

import { APIError, ValidationErrorMeta } from 'types/error';
import { NotificationSeverity } from 'types/notifications';
import { UserAccessLevel } from 'types/user';

import { getValidationDetails } from 'utils/error';

import { GeneralSection } from './general';
import { FORM_FIELDS, KEYS } from './keys';

export const GeneralSectionContainer = () => {
  const { pushToast } = useNotifications();
  const matchId = useMatch(KEYS.ROUTE_MATCH_ID);
  const match = useMatch(KEYS.ROUTE_MATCH);
  const selectedMatch = matchId || match;
  const { uuid } = selectedMatch?.params || {};
  const { data } = useGetMerchantQuery(uuid!);
  const merchant = data?.data ?? ({} as Partial<MerchantInfo>);
  const [values, setValues] = useState<Partial<MerchantInfo>>(merchant);
  const [saveMerchantInfo, { isLoading: isUpdating, error, reset }] = useUpdateMerchantMutation();
  const userLevel = useSelector(selectUserLevel);

  useEffect(() => {
    if (error) {
      if ('data' in error) {
        const { errorMessage } = error.data as APIError;
        pushToast({
          severity: NotificationSeverity.error,
          message: errorMessage,
        });
      }
    }
  }, [error]);

  const handleFormValueChange = (key: string, value: any) => {
    setValues({
      ...values,
      [key]: value,
    });
    reset();
  };

  const handleStatusChange = async (status: MerchantStatus) => {
    const result = await saveMerchantInfo({ status, uuid });
    if ((result as { error: FetchBaseQueryError })?.error) {
      return;
    }
    setValues({
      ...values,
      status,
    });
  };

  const isS4cUser = hasUserS4cRole(userLevel);

  const readOnlyMode = Boolean(userLevel && userLevel === UserAccessLevel.Support);

  const resetFormValues = () => {
    setValues(merchant);
    reset();
  };

  const hiddenFields: Partial<Record<keyof MerchantInfo, boolean>> = {
    [FORM_FIELDS.PROCESSING_FEE]: !isS4cUser,
    [FORM_FIELDS.STATUS]: !isS4cUser,
  };

  const saveFormValues = async () => {
    if (readOnlyMode) {
      return;
    }
    const difference = omitBy(values, (value, key) => isEqual(value, merchant[key as keyof MerchantInfo]));

    await saveMerchantInfo({ ...difference, uuid });
  };

  const isFormChanged = !isEqual(omit(values, FORM_FIELDS.STATUS), omit(merchant, FORM_FIELDS.STATUS));
  const saveErrorMeta = error ? ((error as FetchBaseQueryError).data as { meta: ValidationErrorMeta }).meta : undefined;

  const saveError = saveErrorMeta ? getValidationDetails(saveErrorMeta) : undefined;
  const isSaveAvailable = isFormChanged && isEmpty(saveError);

  return (
    <GeneralSection
      merchant={{ ...merchant, ...values } as MerchantInfo}
      hiddenFields={hiddenFields}
      onFormValueChange={handleFormValueChange}
      onStatusChange={handleStatusChange}
      isFormChanged={isFormChanged}
      isSaveAvailable={isSaveAvailable}
      resetFormValues={resetFormValues}
      saveFormValues={saveFormValues}
      error={saveError}
      isUpdating={isUpdating}
      readOnlyMode={readOnlyMode}
    />
  );
};
