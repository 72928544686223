import {
  APIKeysSectionRoute,
  AccountSettingsRoute,
  GeneralSectionRoute,
  PaymentMethodsSectionRoute,
  UsersSectionRoute,
} from 'pages/account/route';
import { DocumentationRoute } from 'pages/documentation/route';
import { MerchantGeneralSectionRoute, MerchantViewRoute } from 'pages/merchant/route';
import { NotImplementedRoute } from 'pages/notImplemented/route';
import { OrderListRoute, OrderViewRoute } from 'pages/orders/route';
import { ProfileRoute } from 'pages/profile/route';
import { PwcOrderListRoute } from 'pages/pwcOrders/route';

import { AccountType } from 'types/account';
import { GetDefaultLoginRedirectRoute, RouteSetup } from 'types/routing';

import RoutePath from '../../../path';
import { menuItems } from './menuItems';

export const PartnerOwnerRouterSetup = (
  hasAccounts: boolean,
  accountType: AccountType,
  getDefaultLoginRedirectRoute: GetDefaultLoginRedirectRoute,
): RouteSetup => ({
  availableRoutes: {
    [AccountType.Ramp]: [
      {
        path: RoutePath.Ramp.Orders.Root,
        component: OrderListRoute,
      },
      {
        path: RoutePath.Ramp.Orders.ById,
        component: OrderViewRoute,
      },
      {
        path: RoutePath.AccountSettings.Root,
        component: AccountSettingsRoute,
        defaultRedirectRoute: RoutePath.AccountSettings.General,
        children: [
          {
            path: RoutePath.AccountSettings.General,
            component: GeneralSectionRoute,
          },
          {
            path: RoutePath.AccountSettings.PaymentMethods,
            component: PaymentMethodsSectionRoute,
          },
          {
            path: RoutePath.AccountSettings.APIKeys,
            component: APIKeysSectionRoute,
          },
          {
            path: RoutePath.AccountSettings.Users,
            component: UsersSectionRoute,
          },
        ],
      },
      {
        path: RoutePath.Documentation.Root,
        component: DocumentationRoute,
      },
      {
        path: RoutePath.Profile.Root,
        component: ProfileRoute,
      },
    ],
    [AccountType.Pwc]: [
      {
        path: RoutePath.Pwc.Orders.Root,
        component: PwcOrderListRoute,
      },
      {
        path: RoutePath.Pwc.Orders.ById,
        component: NotImplementedRoute,
      },
      {
        path: RoutePath.Pwc.Orders.ReceiptById,
        component: NotImplementedRoute,
      },
      {
        path: RoutePath.AccountSettings.Root,
        component: AccountSettingsRoute,
        defaultRedirectRoute: RoutePath.AccountSettings.General,
        children: [
          {
            path: RoutePath.AccountSettings.General,
            component: GeneralSectionRoute,
          },
          {
            path: RoutePath.AccountSettings.PaymentMethods,
            component: PaymentMethodsSectionRoute,
          },
          {
            path: RoutePath.AccountSettings.APIKeys,
            component: APIKeysSectionRoute,
          },
          {
            path: RoutePath.AccountSettings.Users,
            component: UsersSectionRoute,
          },
        ],
      },
      {
        path: RoutePath.Pwc.Accounts.ById,
        component: MerchantViewRoute,
        defaultRedirectRoute: RoutePath.Pwc.Accounts.General,
        children: [
          {
            path: RoutePath.Pwc.Accounts.General,
            component: MerchantGeneralSectionRoute,
          },
        ],
      },
      {
        path: RoutePath.Documentation.Root,
        component: DocumentationRoute,
      },
      {
        path: RoutePath.Profile.Root,
        component: ProfileRoute,
      },
    ],
  }[accountType],
  menuItems: menuItems[accountType],
  defaultLoginRedirectRoute: getDefaultLoginRedirectRoute(hasAccounts, accountType),
});

export default PartnerOwnerRouterSetup;
